import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ComplementaryProductsState,
  ComplementaryProductsMutations,
} from '~/types/vuex/ComplementaryProducts';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { useExpondoMiddleware, useI18n, usePrefix } from '~/composables';
import extendProducts from '~/helpers/product/extendProducts';
import { ProductVariantGql } from '~/types/product/ProductVariantGql';
import { getProductSearchParams } from '~/helpers/product/getProductSearchParams';
import { COMPLEMENTARY_PRODUCTS_LIMIT } from '~/constants/commerceTools.js';
import { EXPONDO_MIDDLEWARE_ROUTES } from '~/constants/routes';
import { ComplementaryProducts } from '~/types/product/ComplementaryProduct';

export default () => {
  const store = defineModule<
  typeof MODULE_NAMES.COMPLEMENTARY_PRODUCTS_MODULE_NAME,
  ComplementaryProductsMutations,
  ComplementaryProductsState
>(MODULE_NAMES.COMPLEMENTARY_PRODUCTS_MODULE_NAME);

  const { languageAndCountry, countryCode } = useI18n();
  const { makeCommercetoolsRequest } = useExpondoMiddleware();
  const { languagePrefix } = usePrefix();

  const complementaryBaseProduct = computed(() => store.state.complementaryBaseProduct);
  const complementaryProducts = computed(() => store.state.complementaryProducts);
  const cacheId = computed(() => store.state.cacheId);

  const search = async (sku: string) => {
    if (cacheId.value === sku) return;
    store.mutations.setCacheId(sku);
    const productSearchParams = getProductSearchParams(
      { limit: COMPLEMENTARY_PRODUCTS_LIMIT },
      {
        languageAndCountry: languageAndCountry.value,
        languagePrefix,
        isOnStock: true,
      },
    );
    const complementaryProductsData = await makeCommercetoolsRequest<ComplementaryProducts>({
      endpoint: EXPONDO_MIDDLEWARE_ROUTES.CT.GET_COMPLEMENTARY_PRODUCTS_BY_SKU,
      data: [
        { skus: [sku] }, productSearchParams,
      ],
    });
    const productsResults = complementaryProductsData?.data?.products?.data?.products?.results;
    const baseProductResults = complementaryProductsData?.data?.baseProduct?.data?.products?.results;
    const getExtendedProducts = (productsResults: ProductVariantGql[]) => {
      return extendProducts(productsResults, languageAndCountry.value, countryCode.value, { isPublished: true });
    };

    const products = getExtendedProducts(productsResults || []);
    const baseProduct = getExtendedProducts(baseProductResults || [])?.[0];
    store.mutations.setComplementaryProducts(products);
    store.mutations.setComplementaryBaseProduct(baseProduct);
  };

  return {
    search,
    complementaryBaseProduct,
    complementaryProducts,
  };
};
