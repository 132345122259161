const { CUSTOM_QUERIES } = require('../../../../constants/customQueries.js');
const handleCtApiError = require('../handleCtApiError.js');
const getProduct = require('./getProduct');

/**
 * @param {import('@vue-storefront/core').Context} context
 * @param {{ sku: string }} baseParams
 * @param { ProductsSearchParamsExtended } complementaryParams
 */
const getComplementaryProductsBySku = async (context, baseParams, complementaryParams) => {
  try {
    const baseProduct = await getProduct(
      context, baseParams, CUSTOM_QUERIES.COMPLEMENTARY_PRODUCTS.getProductReferencedCategories,
    );
    const categoriesId = baseProduct?.data?.products?.results?.[0]?.masterData
      ?.current?.masterVariant?.attributesRaw[0]?.referencedResourceSet?.map(cat => cat.id);
    if (!categoriesId) {
      return;
    }
    const products = await getProduct(
      context,
      { ...complementaryParams, catId: categoriesId },
      CUSTOM_QUERIES.COMPLEMENTARY_PRODUCTS.getProductsByCategories,
    );
    return {
      data: {
        baseProduct,
        products,
      },
    };
  } catch (error) {
    handleCtApiError(error);
  }
};

module.exports = getComplementaryProductsBySku;
