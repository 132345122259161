import { Logger } from '@vue-storefront/core';
import { EXPONDO_MIDDLEWARE_ROUTES, ROUTES } from '~/constants/routes';
import { getNameForRoute } from '~/helpers/routes/getPageInformation';
import { runExpondoMiddlewareRequest } from '~/helpers/middleware/runExpondoMiddlewareRequest';
import { AllowedMethods } from '~/types/expondoMiddleware';

// this middleware makes sure that the user is not logged in yet.
export default async ({ redirect, $axios, $config, req }) => {
  try {
    const loggedInResponse = await runExpondoMiddlewareRequest({
      $axios,
      $config,
      endpoint: EXPONDO_MIDDLEWARE_ROUTES.CT.ROOT + EXPONDO_MIDDLEWARE_ROUTES.CT.IS_LOGGED_IN,
      method: AllowedMethods.post,
      headers: req?.headers,
    });
    if (loggedInResponse?.data) {
      redirect({ name: getNameForRoute(ROUTES.HOME), replace: true });
    }
  } catch (error) {
    Logger.warn('non-authenticated error: ', String(error));
  }
};
