import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { AxiosResponse } from 'axios';
import { getExpondoMiddlewareUrl } from '~/helpers/env/getMiddlewareUrl';
import { AllowedMethod } from '~/types/expondoMiddleware';
import { IntegrationConfig } from '~/types/global';

export const runExpondoMiddlewareRequest = ({
  $axios,
  $config,
  endpoint,
  method,
  data,
  params,
  headers,
}: {
  $axios: NuxtAxiosInstance;
  $config: IntegrationConfig;
  endpoint: string;
  method: AllowedMethod;
  data?: unknown;
  params?: Record<string, unknown>;
  headers?: Record<string, unknown>;
}): Promise<AxiosResponse> => {
  const currentMiddlewareURL = getExpondoMiddlewareUrl({ $config });

  return $axios({
    method,
    url: `${currentMiddlewareURL}${endpoint}`,
    data,
    params: {
      ...params,
      origin: headers?.host || '',
    },
    baseURL: typeof window !== 'undefined' ? window.location.origin : '',
  });
};
