import { Logger, sharedRef } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';
import type { QueryResponse } from '@vsf-enterprise/commercetools-api';
import type { ApiParams, Customer, SetNewPasswordParams } from '~/utils/ct';
import { errorToString } from '~/helpers/error/errorToString';
import { useForgotPasswordApi } from '~/utils/ct/composables/api/useForgotPasswordApi';

export type CustomerResetPasswordResponse = QueryResponse<'customerResetPassword', Customer>;

export function useForgotPassword() {
  const { setNewPassword: apiSetNewPassword } = useForgotPasswordApi();

  const result = sharedRef<{
    setNewPasswordResult: CustomerResetPasswordResponse | null
  }>({
    setNewPasswordResult: null,
  }, 'useForgotPassword-result');
  const loading = sharedRef<boolean>(false, 'useForgotPassword-loading');
  const error = sharedRef<{
    setNew: string | null
  }>({
    setNew: null,
  }, 'useForgotPassword-error');

  async function setNew(setNewPasswordParams: ApiParams<SetNewPasswordParams>) {
    Logger.debug('useForgotPassword/setNew', setNewPasswordParams);
    try {
      loading.value = true;
      result.value.setNewPasswordResult = await apiSetNewPassword(setNewPasswordParams);
      error.value.setNew = null;
    } catch (err) {
      error.value.setNew = errorToString(err);
      Logger.error('useForgotPassword/setNew', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    setNew,
    result: computed(() => result.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
  };
}
