const { VUESTOREFRONT_COOKIE } = require('#~/constants/cookies.js');

const parseToken = (rawToken) => {
  try {
    return JSON.parse(rawToken);
  } catch {
    return null;
  }
};
const TokenParser = {
  parseToken,
};

/**
 * Helper for checking if given token represents an user session
 *
 * @param token - commercetools access token
 *
 * @returns Boolean indicating if given token is an user session
 */
function isUserSession(token) {
  return Boolean(token?.scope?.includes('customer_id'));
}

/**
 * Endpoint for checking if user is logged in or not. It doesn't use any GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link SetupContext}
 * @returns Boolean indicating if user is logged in or not
 */
module.exports.isLoggedIn = (context) => {
  const token = TokenParser.parseToken(context.req.cookies[VUESTOREFRONT_COOKIE.COMMERCETOOLS_TOKEN]);
  const value = isUserSession(token);
  return value;
};
