const { CUSTOM_QUERIES } = require('../../../../../constants/customQueries');
const handleCtApiError = require('../../handleCtApiError');
const { productTag } = require('../../../../../types/product/attribute/ProductTag');
const { PRODUCT_ATTRIBUTES } = require('../../../../../constants/products');
const { COMPARISON_PRODUCTS_DISPLAY_LIMIT } = require('../../../../../constants/comparisonProducts');
const { getProductIsOnStock } = require('../../../../../helpers/product/getProductStockInfo/getProductStockInfo');
const getProduct = require('../getProduct');

/**
 * @import {ProductVariant} from '~/utils/ct/index'
 * @import {Context} from '@vue-storefront/core'
 * @import {SearchParams} from '~/utils/ct/index'
 */

const SCORE_ON_STOCK = 3;
const SCORE_POPULAR = 2;
const SCORE_ON_SALE = 1;

/**
 * @param {ProductVariant} product
 * @returns {number}
 */
const calculateProductScore = (rawProduct, languageAndCountry) => {
  const product = rawProduct?.masterData?.current?.allVariants?.[0];
  if (!product) {
    return 0;
  }

  const isOnStock = getProductIsOnStock(product);

  const tags = product.attributesRaw?.find(
    attribute => attribute.name === PRODUCT_ATTRIBUTES.TAGS,
  )?.value[languageAndCountry];
  const isPopular = tags?.includes(productTag.Popular);
  const isOnSale = tags?.includes(productTag.Sale);

  let score = 0;
  if (isOnStock) score += SCORE_ON_STOCK;
  if (isPopular) score += SCORE_POPULAR;
  if (isOnSale) score += SCORE_ON_SALE;
  return score;
};

/**
 * @param {{product: ProductVariant, score: number}[]} productsData
 */
const sortComparisonProducts = (productsData) => {
  productsData.sort((productDataA, productDataB) => {
    const scoreA = productDataA.score ?? 0;
    const scoreB = productDataB.score ?? 0;
    return scoreB - scoreA;
  });
};

/**
 * @param {Context} context
 * @param {SearchParams} params
 * @returns {ProductVariant[]}
 */
const getComparisonProducts = async (context, params) => {
  try {
    const productsResponse = await getProduct(
      context, params, CUSTOM_QUERIES.PRODUCT_SEARCH.productComparison,
    );
    const products = productsResponse?.data?.products?.results || [];

    const productsData = products.map(product => ({
      product,
      score: calculateProductScore(product, context.config.locale),
    }));
    sortComparisonProducts(productsData);

    const limitedProduct = productsData
      .slice(0, COMPARISON_PRODUCTS_DISPLAY_LIMIT)
      .map(productData => productData.product);

    return {
      data: limitedProduct,
    };
  } catch (error) {
    throw handleCtApiError(error);
  }
};

module.exports = getComparisonProducts;
