
/**
* @param {Context[req]?} request
* @returns {string}
*/
function getHostname(request) {
  let hostFromWindow;
  if (typeof window !== 'undefined') {
    hostFromWindow = window?.location?.host;
  }
  const hostname = request?.query?.origin ||
    request?.headers?.['x-forwarded-host'] ||
    request?.headers?.host ||
    hostFromWindow;
  return hostname || 'missing-host';
}

module.exports = {
  getHostname,
};
