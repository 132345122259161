import { useVSFContext } from '@vue-storefront/core';
import { AxiosError } from 'axios';
import { EXPONDO_MIDDLEWARE_ROUTES } from '~/constants/routes';
import { errorToString } from '~/helpers/error/errorToString';
import { runExpondoMiddlewareRequest } from '~/helpers/middleware/runExpondoMiddlewareRequest';
import { AllowedMethod, AllowedMethods } from '~/types/expondoMiddleware';
import { ApiResponse } from '~/utils/ct';
import { catchApiErrors } from '~/utils/ct/composables/helpers/apiHelpers';

export const useExpondoMiddleware = () => {
  const { $axios, $config, req } = useVSFContext();
  const makeMiddlewareRequest = async <T> ({
    endpoint,
    method,
    data,
    params,
    catchCallback,
  }: {
    endpoint: string;
    method: AllowedMethod;
    data?: unknown;
    params?: Record<string, unknown>;
    catchCallback?: (error: AxiosError) => void;
  }): Promise<T | null> => {
    try {
      const response = await runExpondoMiddlewareRequest({
        $axios,
        $config,
        endpoint,
        method,
        data,
        params,
        headers: req?.headers,
      });
      return response?.data;
    } catch (error: unknown) {
      if (catchCallback) {
        catchCallback(error as AxiosError);
        return null;
      } else {
        throw new Error(`An error occurred during request to ${endpoint}, ${errorToString(error)}`);
      }
    }
  };

  const makeCommercetoolsRequest = async <T> ({
    endpoint,
    data,
  }: {
    endpoint: string;
    data?: unknown;
  }): Promise<ApiResponse<T> | null> => {
    const responseData = await makeMiddlewareRequest<ApiResponse<T>>({
      endpoint: EXPONDO_MIDDLEWARE_ROUTES.CT.ROOT + endpoint,
      method: AllowedMethods.post,
      data,
    });
    if (responseData) {
      catchApiErrors(responseData);
    }
    return responseData || null;
  };

  return {
    makeMiddlewareRequest,
    makeCommercetoolsRequest,
  };
};
