import type { ApiParams, SetNewPasswordParams } from '~/utils/ct';
import { useIntegrations } from '~/composables';
import { catchApiErrors } from '~/utils/ct/composables/helpers/apiHelpers';

export function useForgotPasswordApi() {
  const { $ct } = useIntegrations({ vsfCt: true });

  const setNewPassword = async ({ tokenValue, newPassword, customQuery }: ApiParams<SetNewPasswordParams>) => {
    const setNewPasswordResult = await $ct.api.customerResetPassword(tokenValue, newPassword, customQuery);
    catchApiErrors(setNewPasswordResult);
    return setNewPasswordResult;
  };

  return {
    setNewPassword,
  };
}
